:root {
	--amarillo-principal: #ffc629;
	--gris-home: #f4f6f9;
	--negro-principal: #1e2327;
}
@font-face {
	font-family: FordAntennaExtraCond_Bold;
	src: url('./Assets/Fonts/FordAntennaExtraCond/FordAntennaComp-Bold.ttf');
}
@font-face {
	font-family: FordAntennaExtraCond_Regular;
	src: url('./Assets/Fonts/FordAntennaExtraCond/FordAntennaComp-Regular.ttf');
}
@font-face {
	font-family: FordAntennaExtraCond_Semibold;
	src: url('./Assets/Fonts/FordAntennaExtraCond/FordAntennaComp-Semibold.ttf');
}
@font-face {
	font-family: FordAntennaExtraCond_Medium;
	src: url('./Assets/Fonts/FordAntennaExtraCond/FordAntennaComp-Medium.ttf');
}
@font-face {
	font-family: FordAntennaExtraCond_Thin;
	src: url('./Assets/Fonts/FordAntennaExtraCond/FordAntennaComp-Thin.ttf');
}
@font-face {
	font-family: FordAntennaExtraCond_Thin;
	src: url('./Assets/Fonts/FordAntennaExtraCond/FordAntennaComp-Thin.ttf');
}
@font-face {
	font-family: FordAntennaExtraCond_Light;
	src: url('./Assets/Fonts/FordAntennaExtraCond/FordAntennaComp-Light.ttf');
}
@font-face {
	font-family: Montserrat_Black;
	src: url('./Assets/Fonts/Monserrat/Montserrat-Black.otf') format('otf'),
		url('./Assets/Fonts/Monserrat/Montserrat-Black.ttf') format('truetype');
}
@font-face {
	font-family: Montserrat_Bold;
	src: url('./Assets/Fonts/Monserrat/Montserrat-Bold.otf') format('otf'),
		url('./Assets/Fonts/Monserrat/Montserrat-Bold.ttf') format('truetype');
}
@font-face {
	font-family: Montserrat_Regular;
	src: url('./Assets/Fonts/Monserrat/Montserrat-Regular.otf') format('otf'),
		url('./Assets/Fonts/Monserrat/Montserrat-Regular.ttf') format('truetype');
}
@font-face {
	font-family: Montserrat_Light;
	src: url('./Assets/Fonts/Monserrat/Montserrat-Light.otf') format('otf'),
		url('./Assets/Fonts/Monserrat/Montserrat-Light.ttf') format('truetype');
}

html {
	height: 100%;
}

textarea:disabled {
	/* background-color: #f1f1f1; */
}
body {
	margin: 0;
	padding: 0;
	font-family: Montserrat_Regular, sans-serif;
	box-sizing: border-box;
	height: 100%;
}

.fade-in-slow {
	animation: fadein 0.9s forwards ease-in;
}

@keyframes fadein {
	from {
		opacity: 0;
	}
	to {
		opacity: 1;
	}
}

.fade-in-fast {
	animation: fadein 0.5s forwards ease-in;
}

@keyframes fadein {
	from {
		opacity: 0;
	}
	to {
		opacity: 1;
	}
}

*:focus {
	outline: 0 !important;
}

.subtitle_televia {
	display: block;
	font-family: Bebas Neue;
	font-size: 3.5vh;
	line-height: 37px;
}

.yellow_text_televia {
	color: var(--amarillo-principal);
}

.newBtn-base {
	min-width: 143.93px;
	height: 47px;
	border-radius: 10px;
	font-family: Montserrat_Bold;
	border: 1.6px solid var(--amarillo-principal);
	background-color: #ffffff;
	font-weight: 700;
	font-size: 0.875rem;
	/* text-transform:capitalize !important; */
	color: var(--negro-principal);
	align-self: center;
	margin: 10px;
	cursor: pointer;
}

.newBtn-inverse {
	min-width: 143.93px;
	height: 47px;
	border-radius: 10px;
	font-family: Montserrat_Bold;
	background-color: var(--amarillo-principal);
	font-weight: 700;
	font-size: 0.875rem;
	/* text-transform:capitalize !important; */
	color: var(--negro-principal);
	align-self: center;
	margin: 10px;
	cursor: pointer;
}

.newBtn-inverse:hover {
	border: 1.6px solid var(--amarillo-principal);
	background-color: #fff;
}

a.newBtn-base {
	display: flex;
	justify-content: center;
	align-items: center;
}

.newBtn-base:hover {
	background-color: var(--amarillo-principal);
	color: var(--negro-principal);
	text-decoration: none;
}
.newBtn-base.btnBase-principal {
	background-color: var(--amarillo-principal);
	color: var(--negro-principal);
}
.newBtn-base:disabled {
	cursor: not-allowed;
}

table .newBtn-base {
	min-width: auto;
}

h2 {
	font-family: Montserrat_Bold;
	font-style: normal;
	font-weight: bold;
	font-size: 1.125rem;
	line-height: 28px;
	text-align: center;
	text-transform: uppercase;
	color: black;
	margin-bottom: 12px;
}
p {
	font-family: Montserrat_Regular;
	font-size: 0.875rem;
	color: var(--negro-principal);
	line-height: 1.3;
}

p.main-p {
	font-size: 1rem;
	font-weight: 400;
}
h1 {
	font-size: 1.875rem;
	/* 45 */
	line-height: 1;
	font-family: Montserrat_Bold;
}

h1.title-component {
	font-family: FordAntennaExtraCond_Bold;
	font-style: normal;
	font-weight: 600;
	/* 30 */
	font-size: 1.875rem !important;

	text-transform: uppercase;
	font-weight: bold;
	color: var(--negro-principal);
	text-align: left;
}

p.title-description {
	color: var(--negro-principal);
	font-size: 17px;
	font-family: Montserrat_Regular;
	text-align: left;
	line-height: 17px;
}
p.title-description span {
	font-family: Montserrat_Bold;
	color: var(--amarillo-principal);
}

.marca-registrada {
	font-size: 0.6em;
	margin-bottom: 2em;
	position: absolute;
}

table td {
	border: 1px dashed #1e2327;
}

table tbody tr td:first-child {
	border-left: 1px solid #e4e5e6 !important;
}

table tbody tr td:last-child {
	border-right: 1px solid #e4e5e6 !important;
}

table thead th {
	background-color: #58595b;
}
table thead th:first-child {
	border-radius: 14px 0 0 0;
}

table thead th:last-child {
	border-radius: 0 14px 0 0;
}

.no-content-container {
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

h2.title-no-content {
	font-family: FordAntennaExtraCond_Bold;
	font-style: normal;
	font-weight: 600;
	/* 30 */
	font-size: 1.875rem !important;
	text-transform: uppercase;
	font-weight: bold;
	color: var(--negro-principal);
	text-align: center;
}
h2.title-no-content > span {
	color: var(--amarillo-principal);
}

.no-content-container img {
	margin: 1rem 0;
	max-width: 97px;
}

.no-content-container p {
	text-align: center;
}

@media (max-width: 1199.98px) {
	h1 {
		font-size: 1.75rem;
		line-height: 1;
	}

	h1.title-component {
		font-size: 1.75rem !important;
		line-height: 1;
	}

	p.title-description {
		font-size: 17px;
		line-height: 17px;
	}
}

@media (max-width: 991.98px) {
	h1 {
		font-size: 1.375rem;
		line-height: 1;
	}
	h2 {
		font-size: 1.125rem;
		line-height: 1;
	}
	p {
		font-family: Montserrat_Regular;
		font-size: 0.75rem;
		color: var(--negro-principal);
		line-height: 1.3;
	}

	p.main-p {
		font-size: 0.875rem;
		font-weight: 400;
	}
}

@media (max-width: 767.98px) {
}

@media (max-width: 575.98px) {
	h1 {
		font-size: 20px;
	}
	h2 {
		font-size: 18px;
	}
}

.sticky-tag {
	bottom: 5%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	left: 44%;
	position: fixed;
	width: 15%;
  z-index: 1;
}

.sticky-tag img {
	max-width: 21.971rem;
}

.sticky-tag {
	left: 52vw;
}

.margin-bottom-page1 {
	bottom: 10%;
	transition: bottom 0.3s ease 0s;
}

.margin-bottom-page2 {
	bottom: 17vh;
	transition: bottom 0.3s ease 0s;
}

.pulse {
	animation: pulse-black 2s infinite;
	border-radius: 0 40px 0 0;
	box-shadow: 0 0 0 0 rgba(0, 0, 0, 1);
	cursor: pointer;
	user-select: none;
}

@keyframes pulse-black {
	0% {
		transform: scale(0.95);
		box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.7);
	}

	70% {
		transform: scale(1);
		box-shadow: 0 0 0 10px rgba(0, 0, 0, 0);
	}

	100% {
		transform: scale(0.95);
		box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
	}
}

.pulse.white {
	animation: pulse-white 2s infinite;
	box-shadow: 0 0 0 0 rgba(255, 255, 255, 1);
}

@keyframes pulse-white {
	0% {
		transform: scale(0.95);
		box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.7);
	}

	70% {
		transform: scale(1);
		box-shadow: 0 0 0 10px rgba(255, 255, 255, 0);
	}

	100% {
		transform: scale(0.95);
		box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
	}
}

@media (max-width: 1921px) {
	
	.sticky-tag.margin-right {
	  border-radius: 0 30px 0 0;
	  bottom: 30%!important;
	  left: 86vw!important;
	  width: 13%;
	}
  
	.margin-bottom-page1 {
		bottom: 10%;
		transition: bottom 0.3s ease 0s;
	}
	.margin-bottom-page2 {
		bottom: 17vh;
		transition: bottom 0.3s ease 0s;
	}
}

@media (max-width: 1537px) {
	.sticky-tag {
		left: 47vw;
	}

	.margin-bottom-page1 {
		bottom: 5%;

		transition: bottom 0.3s ease 0s;
	}
	.margin-bottom-page2 {
		bottom: 10%;
		transition: bottom 0.3s ease 0s;
	}
}

@media (max-width: 1366px) {
	.sticky-tag {
		left: 47vw;
	}

	.margin-bottom-page1 {
		bottom: 5%;

		transition: bottom 0.3s ease 0s;
	}
	.margin-bottom-page2 {
		bottom: 1%;
		transition: bottom 0.3s ease 0s;
	}
}

@media only screen and (min-width: 1240px) and (max-height: 774px) {
	.sticky-tag {
		left: 52vw;
	}

	.sticky-tag img {
		max-width: 15.971rem;
	}

	.margin-bottom-page1 {
		bottom: 10%;

		transition: bottom 0.3s ease 0s;
	}
}

@media (max-width: 1199.98px) {
	.sticky-tag {
		left: 47vw;
		width: 40%;
	}
	.sticky-tag img {
		max-width: 270px;
	}

	.margin-bottom-page1 {
		bottom: 12vh;

		transition: bottom 0.3s ease 0s;
	}
	.margin-bottom-page2 {
		bottom: 15vh;
		transition: bottom 0.3s ease 0s;
	}
}

@media screen and (max-width: 480px) {
	.sticky-tag {
		width: 40%;
	}

	.pulse {
		border-radius: 0 30px 0 0;
	}

  .sticky-tag.right-movil {
    bottom: 2vh;
    left: 58vw;
  }

	.margin-bottom-page1,
	.margin-bottom-page2 {
		bottom: 2vh;
		left: 56vw;
	}
}