body{
  overflow-x: hidden!important;
}

.televia-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  min-height: calc(var(--vh, 1vh) * 100);
}

.encuestaBase-container {
  width:100%;
  height: 100%;
  flex: 1;
  display:flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-color: #FFFFFF;
}

.encuestaBase-container h1{
  font-family: 'Bebas Neue', sans-serif;
}


.datos-container {
  flex: 1;
  display: flex;
  align-items: center;
  /* background: url('./Assets/Textura_blanca.png') white no-repeat center center;

  background-size: cover; */
  justify-content: center;
  background-color: #FFFFFF;

}

.input-black {
  background: #2d2926;
  color: white;
  border: 0;
  border-radius: 0 15px 0 0;
  padding: 10px;
  margin: 5px 0;
  min-width: 100%;
}

input[type='checkbox'] {
  background: gray;
  border: 1px solid black;
  border-radius: 0px;
}

.input-white {
  color: black;
  font-family: Montserrat_Bold, sans-serif;
  padding: 2px 10px;
  margin: 3px 0px;
  min-width: 100%;
  height: 2.5rem;
  background: #F0F2F5;
  border-radius: 10px;
  border:none;
  font-size: 15px;
}
.input-white:disabled {
  color: gray;
}

select.input-white {
  background-color: var(--negro-principal) !important;
  color: var(--amarillo-principal);
}

select.input-white:disabled {
  background-color: var(--negro-principal) !important;
  color: gray;
}

select {
  width: 100%;
  height: 3rem;
  margin: 5px 0;
}

::-webkit-input-placeholder {
  text-align: center;
}

input[type='date'] {
  position: relative;
  background: #fff;
  color: rgba(0, 0, 0, 0.65);
  padding: 10px;
  /* margin: 5px 0; */
  width: 100%;
  /* height: 3rem; */
  border: 1px solid gray;
  /* border-radius: 0 14px 0 0; */
}

input[type='date']:before {
  content: attr(placeholder);
  position: absolute;
  top: 0px;
  left: 0;
  right: 0;
  bottom: 0;
  background: #edeeef;
  color: rgba(0, 0, 0, 0.65);
  pointer-events: none;
  line-height: 1.5;
  padding: 0 0.5rem;
   border-radius:  10px; 
  display: flex;
  align-items: center;
  justify-content: center;
}

input[type='date']:focus:before,
input[type='date']:not([value='']):before {
  display: none;
  background: #fff;
  color: rgba(0, 0, 0, 0.65);
}

label {
  margin: 3px 0;
  font-size: 0.75rem;
}
/* Estilo general para botones */

.normal_yellow_button {
  font-family: Bebas Neue;
  /* color: #231f20; */
  background: #ffc629;
  font-weight: bold;
  font-size: 1.5rem;
  border: 0;
  border-radius: 0px 16px 0px 0px;
  align-self: center;
  margin: 10px;
  cursor: pointer;
  text-transform: uppercase;
  text-align: center;
  vertical-align: middle;
  padding: 5px 15px;
}

.normal_yellow_button:disabled{
  cursor: not-allowed;
}
  
.normal_black_button {
  font-family: Bebas Neue;
  background: #2d2926;
  color: white;
  font-weight: bold;
  font-size: 1.5rem;
  border: 0;
  border-radius: 0px 16px 0px 0px;
  align-self: center;
  margin: 10px;
  cursor: pointer;
  text-transform: uppercase;

  text-align: center;
  vertical-align: middle;
  padding: 5px 15px;
}

button {
  border: 0;
}
.img-aceptar-terminos{
  width: 37vw;
}
.img-promo-dinamica{
  width: 25vw;

}
.parrafoPromoDinamica
{
  font-family: 'Open Sans', sans-serif;
  font-size: 18px;
  text-align: center;
}
.img-promo-error{
  width: 270px;

}
.contenedor-interno-infoPromo{
  display:flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 590px;
  padding:10px;
}
.vertical-default-flexContainer{
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.normal-input-group{
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: flex-end;
  flex-direction: column;
}
.normal-input-group p{
  display: flex;
  align-self: center;
  text-align: center;
  margin-top: 1rem;
}
.normal-input-group label{
  display: flex;
  align-self: flex-start;
  text-align: left;
}
.center-sentence{
  text-align: center;
}

.swal2-styled.swal2-confirm {
  background-color: #f6bc26!important;
}

.swal2-styled.swal2-confirm:focus {
  box-shadow: 0 0 0 3px #ffc1074f;
}

@media only screen and (max-width: 768px) {
  .img-aceptar-terminos{
    width: 90vw;
  }
  .img-promo-dinamica{
    width: 80vw;
  
  }
  .img-promo-error{
    width: 70%;
  
  }
  .contenedor-interno-infoPromo{
    width:100%;
  }

}